@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700;800&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    /* overflow-x: hidden; */
}

body {
    line-height: 1.6;
}

.nav-con {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px 40px;
    background-color: #FFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    z-index: 100;
    width: 100%;
    
}


.logo-container {
    display: flex;
    align-items: center;
}


.logo-container img {
    width: 100%;
    height: 100%;
    margin-right: 15px;
}

.logo-container h1 {
    color: #2B3F6C;
}



.nav-items {
    display: flex;
    gap: 20px;
    margin-left: 70px;
}

/* li{
    display: inline;
} */



.menu-items{
    /* color: #2B3F6C; */
    padding: 5px 15px;
    transition: all 0.3s ease-out;
    white-space: nowrap;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 18px;
}
.menu-items > a{
    color: #2B3F6C;
}

.nav-items a:hover {
    background-color: rgba(43, 63, 108, 0.1);
    border-radius: 8px;
}



.contact {
    margin-left: auto;
    padding: 5px 20px;
    background-color: #2B3F6C;
    border-radius: 20px;
    width:150px;
    display: flex;
    justify-content: center;
    
}

.contact:hover {
    background-color: #1A2A4C;
}

.contact a {
    color: #FFFF;
    white-space: nowrap;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
}

.custom-icon {
    display: none;
    cursor: pointer;
}


/* Default styles for desktop: these items are visible */
.desktop-only {
    display: block;
}

.dropdown{
    display: none;

}



/* Mobile and Tablet Views */
@media (max-width: 788px) {
    .desktop-only {
        display: none;
    }
    
    .logo-container{
        display: flex;
        margin-right: auto;
    }

    
    .logo-container img {
        width: 40px;
        height: 40px;
        margin-right: 15px;
    }
    .logo-container h1 {
        font-size: 20px;
    }

    
    .mobile-nav{
        display: flex;
        align-items: center;
        width: 100%;
    }

    .nav-items {
        margin-left: 0;
        margin-top: 0px;
        flex-direction: column;
        gap: 150px;
    }

    .mobile-items{
        display: flex;
        justify-content: start;
        
    }

    ul{
        display: none;
    }

    .contact {
        display: none;
    }

    .custom-icon {
        display: flex;
        height: 24px;
        width: 24px;
        margin-left: auto;
        margin-right: 10px;

    }

    .custom-icon:hover {
        background-color: rgba(43, 63, 108, 0.1);
    }
    .dropdown {
        display: flex;        
        position: absolute;
        width: 100%;
        background-color: #FFFFFF;
        z-index: 100;
        flex-direction: column;
        top: 0;
        /* transition: transform 0.1s ease-in; Apply ease-in-out to the transform */
        transform: translateY(-120%);
    }
    
    .dropdown.visible {
       
        justify-content: center;
        flex-direction: column;
        top: -30px;
        /* transition: transform 0.3s ease; Apply ease-in-out to the transform */
        transform: translateY(50%);
    }

    .dropdown li {
        padding: 10px 20px;
        border-bottom: 1px solid #2B3F6C;
    }

    .dropdown li a {
        color:#2B3F6C ;
    }
}
