.achievement_con{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
}

.achievemnt_text_con{
   padding-top: 50px;
   padding-bottom: 50px;
}

.achievement_text{
    display:flex;
    width:100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.achievement_text > p{
    color: #2B3F6C;
    font-family: 'Poppins', sans-serif;
    font-size: 21px;
    font-weight: 500;
}

.achievement_text > span{
    color: #2B3F6C;
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-weight: 600;
}

.our_achievement{
    background: linear-gradient(90deg, #86B4D2 0%, #80CFC8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.achievement_award_con{
    display: flex;
    justify-content: center;
    gap: 30px;
    padding: 0px 0px 88px 0px;
    flex-wrap: wrap;
}

.achievement_award{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 10px;
    
}

.award_head{
    font-size: 28px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: var(--Primary-Gradient-Primary-Gradient800, linear-gradient(90deg, #9DC1DA 0%, #A9EDE6 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}
.achievement_award p{
    font-size: 18px;
    text-align: center;
    width: 300px;
}

.achievement_pic_con{
    display: flex;
    width: 100%;
    align-items: start;
    justify-content: center;
    gap:10px;
    overflow-x: hidden;
}


.achievement_pic_col1 > img{
    width: 500px;
    height: 800px;
}

.achievement_pic_col2 > img{
    width: 500px;
    height: 800px;
}

.achievement_pic_col3 > img{
    width: 300px;
    height: 800px;
}

.achievement_events_con{
    display: flex;
    justify-content: center;
    gap: 40px; 
    margin: 50px 88px 160px 88px ;
    align-items: center;
    overflow-x: hidden;
}

.event_wrap{
    display: flex;
    align-items: center;
    gap: 50px;
    justify-content: center;
    
}

.achievement_events_con img:nth-child(1){
    width: 250px;
    height: 120px;

}

.achievement_events_con img:nth-child(2){
    width: 150px;
    height: 96px;
   
}

.achievement_events_con img:nth-child(3){
    width: 189px;
    height: 96px;

}


.achievement_events_con .event_wrap img{
    width: 120px;
    height: 120px;
    overflow-x: hidden;

}

/* .achievement_events_con img:last-child{
    width: 120px;
    height: 120px;

} */

@media (max-width:850px){
     .achievemnt_text_con{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 30px;
    }
    .achievement_award img{
        width:100px;
        height: 100px;
    }
   .award_head{
        text-align: center;
        font-size: 18px;
    }
    .achievement_award  > p{
        text-align: center;
        font-size: 16px;
        width: 240px;
    }
    .achievement_text > p{
        text-align: center;
        font-size: 16px;
    }
    .achievement_text > span{
        text-align: center;
        font-size: 32px;
    }
    .achievement_award_con{
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 0px 0px 30px 0px;
    }
    .achievement_text > p{
        text-align: center;
    }
    .achievement_events_con{
        display: flex;
        flex-direction: column;
        gap: 50px; 
        margin: 50px 0 ;
        align-items: center;
    }
    .achievement_pic_con{
        display: none;
    }

    .achievement_events_con img:nth-child(1){
        width: 150px;
        height: 60px;
    
    }
    
    .achievement_events_con img:nth-child(2){
        width: 150px;
        height: 60px;
       
    }
    
    .achievement_events_con img:nth-child(3){
        width: 150px;
        height: 60px;
    
    }
    
    
    .achievement_events_con .event_wrap img{
        width: 100px;
        height: 80px;
        overflow-x: hidden;
    }
    .event_wrap{
        flex-wrap: wrap;
    }    
}