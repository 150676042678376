@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
    
.fade-in {
animation: fadeIn 1s ease-in 0s 1 normal forwards;
}

.rotate {
    animation: rotate 5s linear infinite;
}

.home{
    background: linear-gradient(90deg,#2E4470,#86D2D3);
    /* height: 2000px; */
    width: 100%;
    display: flex;
    gap:20px;
    overflow-x: hidden;

}

/* span{
    display: block;
} */

span.highlighted{
    color:#95EBE2;
}

.col-1{
    display:flex;
    flex-direction: column;
    justify-content: center;
    width:400px;
    padding-left: 50px;
}

.title{
    display: flex;
    width: 100%;
    text-align: left;
    /* justify-content: center; */
    

}

.title h1{
    font-family: 'Poppins', sans-serif;
    font-size: 60px;
    color:#ffff;
    white-space: nowrap;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.subtitle{
    display: flex;
    /* justify-content: center; */
}

.subtitle > p{
    text-align: left;
    white-space: nowrap;
    font-family: 'Public Sans', sans-serif;
    font-weight: 300;
    color:#ffff
}

.to-form{
    display: flex;
    position: relative;
    top:20px;
    justify-content: center;
    background-color: #9EC2DB;
    align-items: center;
    /* padding:10px; */
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 70px;
    width:350px
}

.to-form p{
    text-align: left;
    white-space: nowrap;
    color:#ffff;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;


}

.col-2{
    display:flex;
    flex-direction: column;
    justify-content: center;
    width:60%;
    align-items: center;
}

.img-con-2{
    width:80%;
    height:80%;
    display: flex;
    justify-content: center;
}

.img-con-2 img{
    width:100%;
    height:100%;
    min-width: 512.04px;
    min-height: 539;
    max-width: 700px;
    max-height: 600px;
    /* padding-left: 20px; */
}

.col-3{
    display:flex;
    flex-direction: column;
    justify-content: center;
    width:50%;
    align-items: center;
    
    /* padding-left: 50px; */
}

.img-con-3{
    width:80%;
    height:80%;
  
    display: flex;
    justify-content: center;
}

.col-3 img{
    width:100%;
    height:100%;
    min-width: 405.25px;
    min-height: 394.63px;
    max-width: 600px;
    max-height: 600px;
}

@media (max-width: 780px) {
    .col-1 {
        width: 100%;
        padding-left: 0; /* Remove left padding */
        align-items: center; /* Center align items vertically */
    }

    .title, .subtitle {
        justify-content: center; /* Center horizontally */
        width: 100%; /* Full width for better alignment */
        text-align: center; /* Center text alignment */
        padding-top: 10px;
    }

    .title h1{
        font-size: 40px;
    }

    .subtitle > p{
        font-size: 15px;
    }

    .title h1, .subtitle > p {
        width: auto; /* Allow natural width for text */
        white-space: normal; /* Allow text to wrap */
        text-align: center;
    }

    .to-form {
        margin-top: 10px; /* Center the button-like div */
        width:150px;
        padding: 10px 20px ;
    }

    .col-2, .col-3 {
        display: none; /* Hide these columns on mobile */
    }
}
