.services-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    width:100%
}
.services-con > h1{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 30px;
    text-align: center;
    /* white-space: nowrap; */
    padding: 50px 0;
    color: #2B3F6C;
}


.row{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    /* padding: 50px ; */
    gap: 50px;
    padding-bottom: 100px;
    

    flex-wrap: wrap; /* Allows items to wrap to the next line */
   
}

.service{
    display: flex;
    width: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-left: 30px; */
    gap: 10px;
}
.service > img{
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
}

.service-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}


.service-text p{
    font-size: 15px;
    font-weight: 300;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-align: center;
    width: 350px;
    color: #2B3F6C;
    line-height: 2.0;
}

.service-text h1{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #2B3F6C;
    text-align: center;
}


@media (max-width: 789px) {
    .services-con > h1 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .row {
        flex-direction: column;
        gap: 20px;
        padding-bottom: 50px;
    }
    .service-text p {
        width: auto; /* Adjust the width for smaller screens */
    }
}

