.mission_con{
   display: flex;
    width: 100%;
    padding: 120px 88px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background: var(--Secondary-Gradient-Secondary-Gradient700, linear-gradient(90deg, #2E4470 0%, #86D2D3 100%));}

.mission_text_con{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.mission_title{
    background: var(--Primary-Gradient-Primary-Gradient800, linear-gradient(90deg, #9DC1DA 0%, #A9EDE6 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
}

.mission_text{
    color: var(--Greyscale-White, #FFF);
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px; /* 145.833% */
}

@media (max-width:788px) {
    .mission_text_con{
        gap:10px;
    }
    .mission_title{
        font-size: 32px;
    }
    .mission_text{
        font-size: 16px;
    }
}