.team_con{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 50px 0px;
    width: 100%;
    background: var(--Background-BabyblueBG, #E1F2FC);
    overflow-x: hidden;
}
.team_text_con{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    padding-bottom: 50px;
}

.team_text_con > h1{
    color: #2B3F6C;
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
}
.team_member_con{
    display:flex;
    justify-content: center;
    align-items: center;
    gap:100px;
    flex-wrap: wrap;
}

.team_member{
    display: flex;
    width: 240px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
}
.team_member > img{
    width: 150px;
    height: 150px;
    border-radius: 30px;
    object-fit: cover;
}

.name{
    color: var(--Lapis-Lapis500, #2F3D6D);
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
}
.position{
    color: #2B3F6C;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
}

.team_doing_con{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 26px;
    align-self: stretch;
    padding: 30px 88px;
}

.team_doing > p{
    color: #2B3F6C;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px; 
}

.team_doing_title{
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 10px 0;
}

.team_doing_title > p{
    color: #2B3F6C;
    font-family: 'Poppins', sans-serif;
    font-size: 56px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.team_doing_title > p > span{
    background: linear-gradient(90deg, #86B4D2 0%, #80CFC8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.team_doing_text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.team_doing_text > p{
    color: #2B3F6C;
    font-family: 'Poppins', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media (max-width:850px) {
    /* .team_member_con{
        gap: 30px;
        flex-wrap: wrap;
        justify-content: center;    
    } */
    .team_text_con{
        padding: 30px;
    }
    .team_text_con > h1{
        font-size: 32px;
        
    }
    .team_member img{
        width: 120px;
        height: 120px;
    }
    .name{
        font-size: 20px;
    }
    .position{
        font-size: 16px;
    }
    .team_doing_con{
        padding:30px 30px;
    }
    .team_doing > p{
        font-size: 16px;
    }
    .team_doing_title{
        padding-bottom: 10px;
    }
    .team_doing_title > p {
        font-size: 32px;
    }
    .team_doing_text > p {
        font-size: 16px;
    }
}