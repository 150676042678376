

.form-con{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    gap: 80px;
    padding: 50px 0;
    width: 100%;
    height: 600px;
    
    /* background-image: linear-gradient(90deg, #2E4470, #86D2D3); */
    background-color: #2E4470;
    /* padding-bottom: 30px; */
    /* padding-top: 30px; */
}
.left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:10px;
    align-items: center;
    margin-left: 20px;
}

.text-form{
    display: flex;
}

.text-form p{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    /* font-size: 56px; */
    color: #ffff;
    /* padding-bottom: 50px; */
    width: 400px;
    overflow-x: hidden;
}

.text-form p span{
    color: #95EBE2;
}

.left > img{
    width: 400px;
    height: 300px;
}

.social{
    display: flex;
    gap: 10px;
    justify-content: start;
    width:400px
}
.social > a > img{
    width: 48px;
    height: 48px;
}

.right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    gap:5px;
    overflow-x: hidden;
    margin-right: 20px;
    
}

.form-field {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    margin-bottom: 15px;
    flex-wrap: wrap;
    overflow-x: hidden;
}

label {
    /* margin-right: 20px; */
    color: #ffff;
    /* Adjusted the width so the label and input can sit side by side. 
       This width is arbitrary. Adjust to fit your content. */
    width: 150px;  
    /* white-space: nowrap; */
}

input[type="text"], 
input[type="email"] {
    width: 100%; /* Adjust width to 100% of the parent container */
    max-width: 500px; /* Maximum width */
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    border: none;
    outline: none;
    overflow-x: hidden; /* Correct value for overflow */
    color: #A1A1A1;
}

input[type="text"]:focus{
    border: 1px solid black;
}   

input[type="text"], 
input[type="email"]::placeholder {
    color: #A1A1A1;
}

.radio-container {
    display: flex;
    align-items: center; /* Aligns the radio button and its label vertically */
    margin-right: 20px; /* Creates some space between each radio button + label pair */
    justify-content: center;
    position: relative;
    left:20px;
    
}

.radio-container label {
    margin-right: 50px;  /* A little space between the radio button and its label */
 /* Resets the width set for other labels */
    margin-bottom: 0;  /* Resets the margin set for other labels */
}

.radio{
    display: flex;
    justify-content: center;
    align-items: center;
    
}

input[type="checkbox"] {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer; /* Changes the cursor to a hand when hovered over */
    position: relative;
    background-color: #ffff;
    border-radius: 50px;
    
}

.submit{
    border-radius: 30px;
    display: flex;
    width:250px;
    height: 50px;
    font-size: 24px;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Poppins', sans-serif;
    background-image: linear-gradient(90deg, #86B3D2, #95EBE2);
    border: none;
    font-weight: 600;
    margin-top: 20px;
    position: static;
    left: 200px;
    overflow-x: hidden;
    
}

.submit:active{
    background-color: #637FE2;
}

.social.mobile{
    display: none;
}

.error{
    color: red;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
}


@media (max-width:1000px) {
    .radio-container{
        flex-direction: column;
        gap:10px;
    }
}

@media (max-width: 788px) {
    .form-con{
        flex-direction: column;
        gap: 20px;
        height: 100%;
        /* padding: 50px 20px; */
        align-items: start;
        justify-content: center;
        
    }
    
    .left > img{
        display: none;
    }

    .left p{
        padding-left: 20px;
        padding-right: 20px;
        width:100%;
    }
    .form-field{
        justify-content: center;
    }


    .right{
        width:100%;
        flex-wrap: nowrap;
       align-items: center;
    }

    input[type="text"], 
    input[type="email"]{
        width: 250px;
    }

    label{
        width: 100px;
    }

    

    .social.mobile{
        display: flex;
        gap: 30px;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;
    }


    .social.desktop{
        display: none;
    }

}
