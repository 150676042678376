.about{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 0.5px solid #2B3F6C;
    width: 90%; /* Adjusted width to 80% */
    padding-top: 100px;
    padding-bottom: 80px;
    margin: 0 auto; /* This centers the .about section horizontally */
    overflow-x: hidden;
}

.about h1{
    padding: 20px 0px;
}

.about p{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-align: center;
    font-size: 21px;
    color: #2B3F6C;
}
.about span{
    font-size: 21px;
    /* white-space: nowrap; */
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    
    text-align: center;
}
#paragraph {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    text-align: center;
    font-size: 21px;
}
#paragraph p {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 21px;
    text-align: center;
    padding-bottom: 50px;
}

.about span{
    font-size: 21px;
    /* white-space: nowrap; */
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    
    text-align: center;
}

.about h1 {
    font-size: 36px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    /* Set the linear gradient as background image */
    background-image: linear-gradient(90deg, #86B4D2, #80CFC8);
    /* Clip the background to the text */
    -webkit-background-clip: text;
    background-clip: text;
    /* Make the text color transparent so the gradient shows through */
    color: transparent;
}

/* hr{
    display: none;
    width: 100%;
    justify-content: center;
    align-items: center;
} */

.benefit-con{
    display: flex;
    padding-top: 50px;
    flex-direction: column;
    padding-left: 50px;
    overflow-x: hidden;
    /* height: 85vh; */
 
}

.benefit-text p{
    color:#2B3F6C;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 24px;
}

.benefit-text h1{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 56px;
}

.benefit-text h1 span{
    background-image: linear-gradient(90deg, #86B4D2, #80CFC8);
    /* Clip the background to the text */
    -webkit-background-clip: text;
    background-clip: text;
    /* Make the text color transparent so the gradient shows through */
    color: transparent;
}

.benefit-list{
    display: flex;
    width: 100%;
    gap:50px;
    justify-content: center;
    overflow-x: hidden;
    /* padding-top: 50px; */
}

.benefit-list ul{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 100%; */
    width: 80%;
}

.benefit-list ul li {
    display: flex;
    align-items: center; /* This will vertically center the items in the list */
    background-color: #ffff;
    margin-bottom: 20px;
    gap: 20px;
    padding: 10px;
    position: relative; /* Needed for the pseudo-element */
    border-radius: 8px;
    /* white-space: nowrap; */
}

.benefit-list ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 10px; /* Width of the border */
    background: linear-gradient(90deg, #95EBE2, #87B7D3);
    border-radius: 8px 0px 0 8px ;}

.benefit-list ul li img{
    padding-left: 20px;
    width: 100px;
    height: 80px;
}

.benefit-list ul li p{
    color: #2B3F6C;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
}



.benefits{
    display: flex;
    background-color: #ffff;
    height: 150px;
}


.computer-con{
    /* width:80%;
    height: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.computer-con img{
    width: 80%;
  
    position: relative;
    bottom: 50px;
    min-width: 320px;
    min-height: 227.1px;
}

@media (max-width:787px) {
    .about p{
        font-size: 14px;
    }
    .about h1{
       font-size: 32px;
       padding: 0;
    }
    .about span{
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        color:#2B3F6C;
    }

    .benefit-con{
        align-items: center;
        justify-content: center;
        padding-left: 0;
    }
    .benefit-list{
        flex-direction: column;
        align-items: center;
        /* padding-top: 50px; */
    }
    .benefit-text p{
        font-size: 18px;
        
    }
    .benefit-text h1{
        font-size: 32px;
        padding-bottom: 20px;
    }
    .benefit-list ul li img{
        display: none;
    }
    .benefit-list p {
        margin-left: 20px;
    }
}



